var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import DataForm from '~/components/common/data-form.vue';
import DataBox from '~/components/common/data-box.vue';
import NumberRange from '~/components/common/number-range.vue';
import RefreshRetireDate from "~/components/case-adjustment/refresh-retire-date.vue";
import { CaseInfoService } from '~/services/business-service/case-info.service';
import { DataImportService } from "~/services/dataimp-service/data-import.service";
import { Getter } from "vuex-class";
import { PageService } from '~/utils/page.service';
import { SortService } from '~/utils/sort.service';
import { Layout, Dependencies } from '~/core/decorator';
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
var SettleCasePool = /** @class */ (function (_super) {
    __extends(SettleCasePool, _super);
    function SettleCasePool() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            principalId: '',
            batchNumber: '',
            personalName: '',
            certificateNo: '',
            caseNumber: "",
            cardNo: "",
            caseType: '',
            phone: "",
            stage: '',
            retireCaseDays: "",
            employerName: "",
            address: "",
            caseNumbers: "",
            certificateNos: "",
            phones: "",
            overdueAmtTotal: {
                min: '',
                max: ''
            },
            leftAmt: {
                min: '',
                max: ''
            },
            delegationDate: [],
            comment: "",
            departCode: "",
            overduePeriods: {
                min: '',
                max: ''
            },
            overdueDays: {
                min: '',
                max: ''
            },
            city: '',
            endCaseDate: []
        };
        _this.rules = {
            certificateNo: [{ min: 6, message: '请输入至少6位的身份证号', trigger: 'blur' }],
            phone: [{ min: 4, message: '请输入至少4位的手机号', trigger: 'blur' }],
            cardNo: [{ min: 6, message: '请输入至少6位的卡号', trigger: 'blur' }]
        };
        _this.dataSet = [];
        _this.dialog = {
            retirement: false,
            retirecase: false,
        };
        _this.currentCase = {};
        _this.selectionList = [];
        _this.caseId = '';
        return _this;
    }
    SettleCasePool.prototype.mounted = function () {
        this.refreshData();
    };
    SettleCasePool.prototype.removeEmptyProperties = function (obj) {
        return Object.fromEntries(Object.entries(obj)
            .filter(function (_a) {
            var _ = _a[0], value = _a[1];
            return value !== null &&
                value !== undefined &&
                value !== "" &&
                (Array.isArray(value) ? value.length > 0 : true) &&
                (typeof value === "object" && !Array.isArray(value) ? Object.keys(value).length > 0 : true);
        }));
    };
    SettleCasePool.prototype.refreshData = function () {
        var _this = this;
        this.dataImportService.findSettleCase(this.removeEmptyProperties(this.searchModel), this.pageService, this.sortService).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    SettleCasePool.prototype.refreshTime = function (scope) {
        this.caseId = scope.row.id;
        this.dialog.retirement = true;
    };
    SettleCasePool.prototype.retirementTime = function () {
        this.caseId = '';
        this.dialog.retirement = true;
    };
    SettleCasePool.prototype.handleResetForm = function () {
        this.searchModel.principalId = '';
        this.searchModel.overdueAmtTotal = {};
        this.searchModel.leftAmt = {};
        this.searchModel.departCode = '';
        this.searchModel.overduePeriods = {};
        this.searchModel.overdueDays = {};
    };
    SettleCasePool.prototype.returnCase = function () {
        var _this = this;
        if (!this.selectionList.length) {
            this.$message('请选择要退案的案件！');
            return;
        }
        var personalIds = this.selectionList.map(function (v) { return v.id; });
        var caseIds = this.selectionList.map(function (v) { return v.id; });
        this.$confirm("确定已选择的案件要进行退案吗？", "提示", { type: "warning" })
            .then(function () {
            _this.caseInfoService.changeRetireCases(personalIds, caseIds).subscribe(function (data) {
                _this.$message.success(" 退案操作成功！");
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    __decorate([
        Dependencies(PageService)
    ], SettleCasePool.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], SettleCasePool.prototype, "sortService", void 0);
    __decorate([
        Getter
    ], SettleCasePool.prototype, "departmentData", void 0);
    __decorate([
        Getter
    ], SettleCasePool.prototype, "orgTreeData", void 0);
    __decorate([
        Dependencies(CaseInfoService)
    ], SettleCasePool.prototype, "caseInfoService", void 0);
    __decorate([
        Dependencies(DataImportService)
    ], SettleCasePool.prototype, "dataImportService", void 0);
    SettleCasePool = __decorate([
        Layout('workspace'),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                PrincipalBatch: PrincipalBatch,
                RefreshRetireDate: RefreshRetireDate,
            },
        })
    ], SettleCasePool);
    return SettleCasePool;
}(Vue));
export default SettleCasePool;
